import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ChooseLocale from "./chooseLocale";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "95%",
  },
  btnFull: {
    width: "100%",
    marginTop: "2vh",
    color: "white",
  },
}));
export default function ProfileForm(props) {
  const classes = useStyles();
  const handleChange = props.onChange;
  const logout = props.logout;
  const { locale } = React.useContext(LocaleContext);
  return (
    <div>
      <div className={clsx()}>
        <h1 className={"header__address"}>{Languages[locale].profile.title}</h1>
      </div>

      <TextField
        label={Languages[locale].global.name}
        value={props.name}
        onChange={handleChange("name")}
        id="standard-start-adornment"
        className={clsx(classes.margin, classes.textField)}
      />
      <TextField
        label={Languages[locale].global.email}
        value={props.email}
        onChange={handleChange("email")}
        id="standard-start-adornment"
        className={clsx(classes.margin, classes.textField)}
      />
      <TextField
        label={Languages[locale].global.phone}
        value={props.phone}
        disabled
        onChange={handleChange("phone")}
        id="standard-start-adornment"
        className={clsx(classes.margin, classes.textField)}
      />
      <TextField
        label={Languages[locale].global.nif}
        value={props.nif}
        type="number"
        onChange={handleChange("nif")}
        id="standard-start-adornment"
        className={clsx(classes.margin, classes.textField)}
        style={{ display: "None" }}
      />
      
      <Button
        variant="contained"
        color="primary"
        onClick={logout}
        className={clsx(classes.btnFull)}
        endIcon={<ExitToAppIcon />}
        style={{
          borderRadius: "50px",
          backgroundColor: "#141A30",
          padding: "12px 12px",
        }}
      >
        {Languages[locale].global.logout}
      </Button>
    </div>
  );
}
