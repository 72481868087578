import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AppLayout from "./layout";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import BackButton from "../components/backButton";
import AuthService from "../services/auth-service";
import Link from "@material-ui/core/Link";

import useStyles from "../css/forgot";
import logo from "../FoodSVG.svg";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
export default function SignIn() {
  const classes = useStyles();
  const { locale } = React.useContext(LocaleContext);
  const history = useHistory();
  const [phone, setPhone] = React.useState("");

  const handleOnChange = (value) => {
    setPhone(value);
  };
  const handleChange = (prop) => (event) => {
    //setValues({ ...values, [prop]: event.target.value });
    switch (prop) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    //{username, phone, password, role: user}
    const res = await AuthService.getGenericCode({
      phone,
    });
    if (res.data.success) {
      history.push("/verify?forgot=1");
    }
  };

  return (
    <AppLayout>
      <BackButton history={history} location={"/signin/"} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} className={classes.avatar} alt="avatar" />

          <Typography style={{ margin: "20px 0px", color: "#354636" }}>
            {" "}
            Insert your phone number
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleRegister}>
            <input
              className={"nxt input-round"}
              placeholder="Phone"
              value={phone}
              onChange={handleChange("phone")}
              type="text"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{
                borderRadius: "50px",
                backgroundColor: "#EA212C",
                padding: "12px 12px",
              }}
            >
              Get Validation Code
            </Button>
          </form>
        </div>
        <Box mt={40}>
          <p
            style={{
              textAlign: "center",
              lineHeight: "14px",
              fontSize: "10px",
              fontWeight: "normal",
              fontStyle: "normal",
              marginTop: "10vh",
            }}
          >
            {"Copyright © "}
            <Link
              color="inherit"
              href="/reckon.ai"
              style={{ color: "#141A30" }}
            >
              {process.env.main || "Reckon.ai"}
            </Link>{" "}
            {new Date().getFullYear()}
            {""}
          </p>
        </Box>
      </Container>
    </AppLayout>
  );
}
